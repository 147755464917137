<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="card card-custom card-stretch">
                    <div class="card-body container pt-4">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="name"
                                            :rules="[v => !!v || 'Cần phải nhập tên video']"
                                            class="required"
                                            label="Tên"
                                            required
                                        ></v-text-field>
                                    </div>

                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="url"
                                            :rules="[v => !!v || 'Cần phải nhập URL']"
                                            class="required"
                                            label="URL"
                                            required
                                        ></v-text-field>
                                    </div>


                                    <div class="container col-md-12" style="display:inherit;">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <v-autocomplete
                                                    v-model="tags"
                                                    :items="all_tags"
                                                    label="Từ khoá"
                                                    item-text="Name"
                                                    item-value="Name"
                                                    :multiple="true"
                                                    chips
                                                    deletable-chips
                                                ></v-autocomplete>
                                            </div>
                                            <div class="col-md-3" align="end" style="display: flex;justify-content: center;flex-direction: column;">
                                                <v-dialog v-model="dialog" persistent max-width="600px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn text v-bind="attrs" v-on="on">
                                                            <v-icon small class="mr-2">mdi-plus</v-icon>Thêm từ khoá
                                                        </v-btn>
                                                    </template>
                                                    <v-card>
                                                        <v-card-title>
                                                            <span class="headline">Thêm từ khoá</span>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="new_tag_name" label="Tên*" required></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="new_tag_slug" label="Slug" hint="slug-1"></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                            <small>*Trường bắt buộc</small>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="blue darken-1" text @click="dialog = false">Huỷ</v-btn>
                                                            <v-btn color="blue darken-1" text @click="create_tag()">Thêm</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                                                <!--<v-btn @click="show_create_tag()" text><v-icon small class="mr-2">mdi-plus</v-icon>Thêm từ khoá</v-btn>-->
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="status"
                                            :rules="[v => !!v || 'Cần phải nhập trạng thái']"
                                            class="required"
                                            label="Trạng thái"
                                            type="number"
                                            required
                                        ></v-text-field>
                                    </div>
                                    

                                    <input type="file" ref="upload" @change="upload_server" style="display:none;" multiple>
                                </div>
                                <div class="col-md-4">
                                    <div class="col-md-12">
                                        <h5>Hình ảnh</h5>
                                        <div v-if="!thumbnail"><small>Sử dụng ảnh tỷ lệ 3:4</small></div>
                                        <template v-if="!thumbnail">
                                            <v-btn color="info" @click="browse_file"><v-icon class="mr-2">mdi-cloud-upload</v-icon>Tải ảnh lên...</v-btn>
                                        </template>
                                        <template v-if="thumbnail">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <img :src="thumbnail" width="100%" />
                                                </div>
                                                <div class="col-md-12">
                                                    <v-btn class="ma-2 float-left" color="error" @click="delete_images">Xoá ảnh</v-btn>
                                                    <!-- <v-btn class="ma-2 float-left" color="info" @click="browse_file">Thêm ảnh</v-btn> -->
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    
                                </div>


                                


                                <div class="col-md-12">
                                    <v-btn
                                        class="ma-2 float-right"
                                        :loading="loading"
                                        color="info"
                                        @click="create_video"
                                    >
                                        Thêm
                                    </v-btn>
                                    <v-btn
                                        class="ma-2 float-right"
                                        :loading="loading"
                                        @click="cancel"
                                    >
                                        Huỷ
                                    </v-btn>
                                </div>
                            </div>

                        </v-form>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

// for tabs
$color-transition-duration: 0.8s;
$accent-color: #2980b9;
$x-hover-color: #c0392b;
$smaller-nav-item-padding: 8px;
$icon-size: 0.875rem;

ul.nav-tabs {
    margin-top: 12px;
}

.card.tab-contents {
    border-top: none;
    border-radius: 0;
} 

.nav-link.tab {
    border-radius: 0;
    
    //Override the 16px Bootstrap default to give it a more tab-like feel
    padding-right: $smaller-nav-item-padding;
    
    span {
        transition: color $color-transition-duration;    
        color: black;
        opacity: 0.54;
        &:hover {
            color: $accent-color;
        }
    }
    
    &.active {
        span {
            opacity: 1;
        }
    }
           
    .icon-btn {
        margin-left: 6px;
        text-decoration: none;    
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        font-size: $icon-size;

            .fa {
                opacity: 0.54;
                transition: color $color-transition-duration;

                &:hover {
                    color: $x-hover-color;
                }
            }   
    }
    
    &.add-btn {
        padding-left: $smaller-nav-item-padding;        
        
        .icon-btn {
            color: $accent-color;
            margin: 0;    
        }
    }
}

.tab-icon:hover {
    color: #777;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    metaInfo: {
        title: 'Thêm video'
    },
    data() {
        return {
            valid: true,
            name: "",
            status: 0,
            tags: [],
            thumbnail: "",
            url: "",

            //for tag modal
            dialog: false,
            new_tag_name: "",
            new_tag_slug: "",

            loading: false,
            name_rules: [],

        };
    },
    watch: {
        
    },
    computed: {
        all_tags() {
            return this.$store.getters['tags/get_tags'];
        }
    },
    components: {
        
    },
    methods: {
        create_video() {
            // console.log("this.cate_id", this.cate_id);
            if (this.$refs.form.validate()) {

                // return;
                this.loading = true;
                var payload = {
                    Name: this.name,
                    Url: this.url,
                    Status: parseInt(this.status) || 0,
                    Thumbnail: this.thumbnail,
                    Tag: this.tags,
                    toast: this.$root.$bvToast,
                }
                this.$store.dispatch('videos/create_video', payload).then(() => {
                    this.loading = false;
                    this.$router.push({ name: 'videos-index' })
                }).catch(() => {
                    this.loading = false;
                });
            }
        },
        cancel() {
            this.$router.push({ name: 'videos-index' })
        },
        browse_file() {
            this.$refs.upload.click();
        },
        async upload_server() {
            // var self = this;
            var files = this.$refs.upload.files;
            if(files) {
                for(var i = 0; i < files.length; i++) {
                    var url = await this.upload_one(files[i]);
                    this.thumbnail = url;
                }
            }
        },
        async upload_one(file) {
            var res = await this.$store.dispatch('uploads/upload', {file: file});
            return res.data.url;
        },
        delete_images() {
            this.thumbnail = "";
        },
        create_tag() {
            this.loading = true;
            var payload = {
                Name: this.new_tag_name,
                Slug: this.new_tag_slug,
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('tags/create_tag', payload).then(() => {
                this.loading = false;
                this.dialog = false;
                this.$store.dispatch('tags/get_all');
            }).catch(() => {
                this.loading = false;
                this.dialog = false;
            });
        },

        
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Video", route: "index" }, { title: "Thêm video" } ]);
        this.$store.dispatch('tags/get_all');
    }
};
</script>



